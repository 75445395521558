import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import io from "socket.io-client";
import styled from "styled-components";
import effect1Video from "./assets/effect1.webm";
import effect2Video from "./assets/effect2.webm";
import effect3Video from "./assets/effect3.webm";
import effect4Video from "./assets/effect4.webm";
import effect5Video from "./assets/effect5.webm";
import effect6Video from "./assets/effect6.webm";
import effect7Video from "./assets/effect7.webm";
import effect8Video from "./assets/effect8.webm";
import effect9Video from "./assets/effect9.webm";
import effect10Video from "./assets/effect10.webm";
import effect11Video from "./assets/effect11.webm";
import logo from "./assets/Logo.png";

// Map of effect video sources for easier rendering
const effectVideos = {
  1: effect1Video,
  2: effect2Video,
  3: effect3Video,
  4: effect4Video,
  5: effect5Video,
  6: effect6Video,
  7: effect7Video,
  8: effect8Video,
  9: effect9Video,
  10: effect10Video,
  11: effect11Video,
};

const socket = io.connect("https://www.mypawers.com", {
  path: "/api",
});

//const socket = io("http://localhost", { path: "/api" }); // Updated socket connection with path

const App = () => {
  const [roomId, setRoomId] = useState("");
  const [joined, setJoined] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const peerConnectionRef = useRef({});
  const [remoteStreams, setRemoteStreams] = useState([]);
  const [activeEffects, setActiveEffects] = useState({
    1: { active: false, duration: 8000 }, // 5 seconds
    2: { active: false, duration: 8000 }, // 2 seconds
    3: { active: false, duration: 8000 },
    4: { active: false, duration: 8000 },
    5: { active: false, duration: 8000 },
    6: { active: false, duration: 8000 },
    7: { active: false, duration: 8000 },
    8: { active: false, duration: 8000 },
    9: { active: false, duration: 8000 },
    10: { active: false, duration: 8000 },
    11: { active: false, duration: 8000 },
  });
  const [peerConnections, setPeerConnections] = useState({});
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [preJoinCamera, setPreJoinCamera] = useState(true);
  const [localStream, setLocalStream] = useState(null);
  const [fullScreenType, setFullScreenType] = useState(null);
  const [inFullScreen, setInFullScreen] = useState(false);
  const localOverlayVideoRef = useRef(null);
  const [minWidth, setMinWidth] = useState(1200);
  const [minHeight, setMinHeight] = useState(1200);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");

  const servers = { iceServers: [{ urls: "stun:stun.l.google.com:19302" }] };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setInFullScreen(!!document.fullscreenElement);
      if (!document.fullscreenElement) {
        setFullScreenType(null);
      }
    };
    document.addEventListener("fullscreenchange", fullscreenChangeHandler);
    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, []);

  useEffect(() => {
    socket.on("user-joined", async (id) => {
      console.log(`User joined: ${id}`);
      const peerConnection = new RTCPeerConnection(servers);
      peerConnectionRef.current[id] = peerConnection;
      setPeerConnections((prev) => ({ ...prev, [id]: peerConnection }));

      const stream = localVideoRef.current.srcObject;
      stream
        ?.getTracks()
        .forEach((track) => peerConnection.addTrack(track, stream));

      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("ice-candidate", {
            roomId,
            candidate: event.candidate,
            senderId: socket.id,
            targetId: id,
          });
        }
      };

      peerConnection.ontrack = (event) => {
        setRemoteStreams((prevStreams) => {
          const streamExists = prevStreams.find(
            (s) => s.id === event.streams[0].id
          );
          if (!streamExists) {
            return [...prevStreams, event.streams[0]];
          }
          return prevStreams;
        });
      };

      createOffer(id);
    });

    socket.on("offer", async ({ offer, senderId }) => {
      console.log("Received offer from:", senderId);
      const peerConnection = new RTCPeerConnection(servers);
      peerConnectionRef.current[senderId] = peerConnection;
      setPeerConnections((prev) => ({ ...prev, [senderId]: peerConnection }));

      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("ice-candidate", {
            roomId,
            candidate: event.candidate,
            senderId: socket.id,
            targetId: senderId,
          });
        }
      };

      peerConnection.ontrack = (event) => {
        setRemoteStreams((prevStreams) => {
          const streamExists = prevStreams.find(
            (s) => s.id === event.streams[0].id
          );
          if (!streamExists) {
            return [...prevStreams, event.streams[0]];
          }
          return prevStreams;
        });
      };

      const stream = localVideoRef.current.srcObject;
      stream
        ?.getTracks()
        .forEach((track) => peerConnection.addTrack(track, stream));

      await peerConnection.setRemoteDescription(offer);
      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);
      socket.emit("answer", {
        roomId,
        answer,
        senderId: socket.id,
        targetId: senderId,
      });
    });

    socket.on("answer", async ({ answer, senderId }) => {
      console.log("Received answer from:", senderId);
      const peerConnection = peerConnectionRef.current[senderId];
      if (peerConnection) {
        await peerConnection.setRemoteDescription(answer);
      }
    });

    socket.on("ice-candidate", async ({ candidate, senderId }) => {
      console.log("Received ICE candidate from:", senderId);
      const peerConnection = peerConnectionRef.current[senderId];
      if (peerConnection && candidate) {
        await peerConnection.addIceCandidate(candidate);
      }
    });

    // Listen for effect-clicked events for effects 1-11:
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach((effectId) => {
      socket.on(`effect-${effectId}-clicked`, () => {
        console.log(`Effect ${effectId} button was clicked`);
        setActiveEffects((prev) => ({
          ...prev,
          [effectId]: { ...prev[effectId], active: true },
        }));
        setTimeout(() => {
          setActiveEffects((prev) => ({
            ...prev,
            [effectId]: { ...prev[effectId], active: false },
          }));
        }, activeEffects[effectId].duration);
      });
    });

    // Add this new listener for user disconnection
    socket.on("user-disconnected", (disconnectedUserId) => {
      console.log(`User disconnected: ${disconnectedUserId}`);

      if (peerConnectionRef.current[disconnectedUserId]) {
        peerConnectionRef.current[disconnectedUserId].close();
        delete peerConnectionRef.current[disconnectedUserId];
      }

      setRemoteStreams((prevStreams) =>
        prevStreams.filter((stream) => {
          const senderConnection = Object.entries(
            peerConnectionRef.current
          ).find(([_, pc]) =>
            pc
              .getReceivers()
              .some(
                (receiver) => receiver.track.id === stream.getTracks()[0]?.id
              )
          );
          return senderConnection && senderConnection[0] !== disconnectedUserId;
        })
      );
    });

    socket.on("user-left", (userId) => {
      console.log(`User left: ${userId}`);
      if (peerConnectionRef.current[userId]) {
        peerConnectionRef.current[userId].close();
        delete peerConnectionRef.current[userId];
      }

      setRemoteStreams((prevStreams) =>
        prevStreams.filter((stream) => {
          const senderConnection = Object.entries(
            peerConnectionRef.current
          ).find(([_, pc]) =>
            pc
              .getReceivers()
              .some(
                (receiver) => receiver.track.id === stream.getTracks()[0]?.id
              )
          );
          return senderConnection && senderConnection[0] !== userId;
        })
      );
    });

    return () => {
      socket.off();
      Object.values(peerConnectionRef.current).forEach((pc) => pc.close());
    };
  }, [roomId]);

  useEffect(() => {
    if (localOverlayVideoRef.current && localStream) {
      localOverlayVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    const getCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameras(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedCamera(videoDevices[0].deviceId);
        }
      } catch (err) {
        console.error("Error getting cameras:", err);
      }
    };

    getCameras();
  }, []);

  const startCall = async () => {
    setJoined(true);
    if (preJoinCamera) {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
          width: { ideal: 3840 },
          height: { ideal: 2160 },
          frameRate: { ideal: 60, max: 60 },
        },
        audio: false,
      });
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
      setLocalStream(stream);
    } else {
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = new MediaStream();
      }
      setLocalStream(null);
    }
    setIsCameraOn(preJoinCamera);
    socket.emit("join-room", roomId);
  };

  const createOffer = async (targetId) => {
    const peerConnection = peerConnectionRef.current[targetId];
    if (peerConnection) {
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);
      socket.emit("offer", { roomId, offer, senderId: socket.id, targetId });
    }
  };

  const handleEffectClick = (effectId) => {
    // Apply effect immediately for local user
    setActiveEffects((prev) => ({
      ...prev,
      [effectId]: { ...prev[effectId], active: true },
    }));
    setTimeout(() => {
      setActiveEffects((prev) => ({
        ...prev,
        [effectId]: { ...prev[effectId], active: false },
      }));
    }, activeEffects[effectId].duration);

    // Emit event for remote users
    socket.emit("effect-clicked", { roomId, effectId });
  };

  const handleFullscreen = (event) => {
    const container = event.currentTarget.closest(".video-wrapper");
    if (!document.fullscreenElement) {
      container.requestFullscreen?.() ||
        container.webkitRequestFullscreen?.() ||
        container.mozRequestFullScreen?.() ||
        container.msRequestFullscreen?.();
      setFullScreenType(container.dataset.type);
    } else {
      document.exitFullscreen?.() ||
        document.webkitExitFullscreen?.() ||
        document.mozCancelFullScreen?.() ||
        document.msExitFullscreen?.();
      setFullScreenType(null);
    }
  };

  const toggleCamera = async () => {
    if (isCameraOn) {
      const stream = localVideoRef.current.srcObject;
      if (stream) {
        stream.getVideoTracks().forEach((track) => track.stop());
        localVideoRef.current.srcObject = new MediaStream();
        setLocalStream(null);
      }
    } else {
      try {
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 3840 },
            height: { ideal: 2160 },
            frameRate: { ideal: 60, max: 60 },
          },
          audio: false,
        });
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = newStream;
        }
        setLocalStream(newStream);
        Object.values(peerConnectionRef.current).forEach((pc) => {
          newStream.getTracks().forEach((track) => {
            pc.addTrack(track, newStream);
          });
        });
      } catch (err) {
        console.error("Error accessing camera:", err);
        return;
      }
    }
    setIsCameraOn(!isCameraOn);
  };

  const handleExit = () => {
    Object.values(peerConnectionRef.current).forEach((pc) => pc.close());
    peerConnectionRef.current = {};

    const stream = localVideoRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }

    setJoined(false);
    setRemoteStreams([]);
    setRoomId("");
    setPeerConnections({});

    if (roomId) {
      socket.emit("leave-room", roomId);
    }
  };

  // List of effect IDs
  const effectIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  // Map each effect ID to a custom name
  const effectNames = {
    1: "Draw Heart",
    2: "Blow Kiss",
    3: "Hug",
    4: "Kiss on Lips",
    5: "Toast",
    6: "Heart with Hands",
    7: "Touch Hands on Screen",
    8: "Same Dance",
    9: "Take a Selfie",
    10: "Mirror Moves",
    11: "Win",
  };

  return (
    <Container>
      <LogoImage src={logo} alt="Logo" />
      {joined && (
        <>
          <RoomDisplay>Room: {roomId}</RoomDisplay>
          <ExitButton onClick={handleExit}>Exit Room</ExitButton>
          <InputContainer>
            <label>
              Min Width:
              <input
                type="text"
                value={minWidth}
                onChange={(e) => setMinWidth(e.target.value)}
              />
            </label>
            <label>
              Min Height:
              <input
                type="text"
                value={minHeight}
                onChange={(e) => setMinHeight(e.target.value)}
              />
            </label>
          </InputContainer>
        </>
      )}
      {!joined ? (
        <JoinContainer>
          <input
            type="text"
            placeholder="Enter Room ID"
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
          />
          <select
            value={selectedCamera}
            onChange={(e) => setSelectedCamera(e.target.value)}
            disabled={!preJoinCamera}
          >
            {cameras.map((camera) => (
              <option key={camera.deviceId} value={camera.deviceId}>
                {camera.label || `Camera ${cameras.indexOf(camera) + 1}`}
              </option>
            ))}
          </select>
          <button
            className="camera-toggle"
            onClick={() => setPreJoinCamera(!preJoinCamera)}
          >
            {preJoinCamera ? "🎥" : "❌"} Camera {preJoinCamera ? "On" : "Off"}
          </button>
          <button onClick={startCall}>Join Room</button>
        </JoinContainer>
      ) : (
        <VideoContainer minWidth={minWidth} minHeight={minHeight}>
          <div
            className={`video-wrapper ${Object.entries(activeEffects)
              .filter(([_, effect]) => effect.active)
              .map(([id]) => `effect-${id}-active`)
              .join(" ")}`}
            data-type="local"
          >
            <video ref={localVideoRef} autoPlay playsInline muted />
            <button className="fullscreen-btn" onClick={handleFullscreen}>
              ⛶
            </button>
            <button className="camera-btn" onClick={toggleCamera}>
              {isCameraOn ? "🎥" : "❌"}
            </button>
            {effectIds.map((id) => (
              <div key={id} className={`effect-overlay effect-${id}`}>
                <video src={effectVideos[id]} autoPlay loop muted playsInline />
              </div>
            ))}
          </div>

          {remoteStreams.map((stream, index) => (
            <div
              key={index}
              className={`video-wrapper ${Object.entries(activeEffects)
                .filter(([_, effect]) => effect.active)
                .map(([id]) => `effect-${id}-active`)
                .join(" ")}`}
              data-type="remote"
            >
              <video
                ref={(el) => {
                  if (el && el.srcObject !== stream) {
                    el.srcObject = stream;
                  }
                }}
                autoPlay
                playsInline
              />
              <button className="fullscreen-btn" onClick={handleFullscreen}>
                ⛶
              </button>
              {effectIds.map((id) => (
                <div key={id} className={`effect-overlay effect-${id}`}>
                  <video
                    src={effectVideos[id]}
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                </div>
              ))}
            </div>
          ))}

          <EffectButtons>
            {effectIds.map((id) => (
              <button key={id} onClick={() => handleEffectClick(id)}>
                {effectNames[id]}
              </button>
            ))}
          </EffectButtons>
        </VideoContainer>
      )}
      {fullScreenType === "remote" &&
        inFullScreen &&
        localStream &&
        document.fullscreenElement &&
        ReactDOM.createPortal(
          <LocalFeedOverlay>
            <video
              ref={(node) => {
                if (node && localStream) {
                  node.srcObject = localStream;
                  node
                    .play()
                    .catch((err) =>
                      console.error("Error playing local overlay video", err)
                    );
                }
              }}
              autoPlay
              playsInline
              muted
            />
          </LocalFeedOverlay>,
          document.fullscreenElement
        )}
    </Container>
  );
};

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #0a0b1e 0%, #1a1b3d 100%);
  color: white;
  padding: 10px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
        2px 2px at 20px 30px,
        #ffffff,
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(2px 2px at 40px 70px, #ffffff, rgba(0, 0, 0, 0)),
      radial-gradient(2px 2px at 50px 160px, #ffffff, rgba(0, 0, 0, 0)),
      radial-gradient(2px 2px at 90px 40px, #ffffff, rgba(0, 0, 0, 0)),
      radial-gradient(2px 2px at 130px 80px, #ffffff, rgba(0, 0, 0, 0)),
      radial-gradient(2px 2px at 160px 120px, #ffffff, rgba(0, 0, 0, 0));
    background-repeat: repeat;
    background-size: 200px 200px;
    animation: twinkle 4s ease-in-out infinite alternate;
    opacity: 0.5;
  }

  @keyframes twinkle {
    0% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.7;
    }
  }

  @media (max-width: 768px) {
    padding: 10px 5px;
  }
`;

const LogoImage = styled.img`
  height: 250px;
  margin: 20px 0 40px 0;
  position: relative;
  z-index: 1;
  align-self: center;

  @media (max-width: 768px) {
    height: 150px;
    margin: 10px 0 20px 0;
  }
`;

const JoinContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: rgba(255, 255, 255, 0.05);
  padding: 30px;
  border-radius: 24px;
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;

  input {
    padding: 16px 24px;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 16px;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: #7928ca;
      box-shadow: 0 0 0 2px rgba(121, 40, 202, 0.2);
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  select {
    padding: 16px 24px;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 16px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: #7928ca;
      box-shadow: 0 0 0 2px rgba(121, 40, 202, 0.2);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    option {
      background: #1a1b3d;
      color: white;
    }
  }

  button {
    padding: 16px 24px;
    border-radius: 12px;
    border: none;
    background: linear-gradient(45deg, #7928ca, #ff0080);
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 20px rgba(121, 40, 202, 0.3);
    }

    &:active {
      transform: translateY(0);
    }
  }

  .camera-toggle {
    background: rgba(255, 255, 255, 0.1);
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;

    input,
    button {
      padding: 12px 18px;
      font-size: 14px;
    }
  }
`;

const VideoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  .video-wrapper {
    position: relative;
    width: 360px;
    height: 240px;
    border-radius: 20px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
    }

    &:fullscreen {
      background: transparent !important;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        min-width: ${({ minWidth }) => minWidth}px;
        min-height: ${({ minHeight }) => minHeight}px;
        max-width: 100vh;
        max-height: 100vw;
        object-fit: contain;
        transform: translate(-50%, -50%) rotate(90deg) scale(1.3);
      }

      .effect-overlay {
        position: fixed;
        font-size: min(50vw, 50vh);
        z-index: 100000;
        margin-top: 100px;
      }
    }

    &:-webkit-full-screen {
      background: pink !important;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        max-width: 100vh;
        max-height: 100vw;
        object-fit: contain;
        transform: translate(-50%, -50%) rotate(90deg) scale(1.3);
      }

      .effect-overlay {
        position: fixed;
        font-size: min(50vw, 50vh);
        z-index: 100000;
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 20px;
      transform: rotate(90deg);
    }

    .fullscreen-btn {
      position: absolute;
      bottom: 15px;
      right: 15px;
      background: rgba(255, 255, 255, 0.1);
      color: white;
      border: none;
      border-radius: 12px;
      padding: 8px 12px;
      cursor: pointer;
      transition: all 0.3s ease;
      backdrop-filter: blur(5px);

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: scale(1.1);
      }
    }

    .camera-btn {
      position: absolute;
      bottom: 15px;
      right: 60px;
      background: rgba(255, 255, 255, 0.1);
      color: white;
      border: none;
      border-radius: 12px;
      padding: 8px 12px;
      cursor: pointer;
      transition: all 0.3s ease;
      backdrop-filter: blur(5px);

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: scale(1.1);
      }
    }

    p {
      position: absolute;
      bottom: 15px;
      left: 15px;
      margin: 0;
      padding: 8px 16px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(5px);
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
    }

    @media (orientation: portrait) {
      .local-video {
        transform: rotate(90deg);
        transform-origin: center;
      }

      .remote-video {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }
  }

  .effect-overlay {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 120px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    filter: drop-shadow(0 0 10px rgba(255, 0, 128, 0.5));
    z-index: 100000;

    &.effect-1,
    &.effect-2,
    &.effect-3,
    &.effect-4,
    &.effect-5,
    &.effect-6,
    &.effect-7,
    &.effect-8,
    &.effect-9,
    &.effect-10,
    &.effect-11 {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: none;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        mix-blend-mode: screen;
        transform: scale(0.8);
        transition: transform 0.5s ease-in-out;
      }
    }
  }

  .effect-1-active .effect-1,
  .effect-2-active .effect-2,
  .effect-3-active .effect-3,
  .effect-4-active .effect-4,
  .effect-5-active .effect-5,
  .effect-6-active .effect-6,
  .effect-7-active .effect-7,
  .effect-8-active .effect-8,
  .effect-9-active .effect-9,
  .effect-10-active .effect-10,
  .effect-11-active .effect-11 {
    display: block;
    opacity: 1;

    video {
      transform: scale(1.4);
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
    gap: 15px;

    .video-wrapper {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;

      .fullscreen-btn,
      .camera-btn {
        padding: 6px 10px;
        font-size: 14px;
      }

      .camera-btn {
        right: 50px;
      }
    }
  }
`;

const EffectButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 15px;
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);

  button {
    position: relative;
    height: 60px;
    padding: 0 20px;
    border: none;
    border-radius: 16px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      border-color: rgba(255, 255, 255, 0.2);
    }

    &:active {
      transform: translateY(0);
    }
  }

  /* Individual button colors */
  button:nth-child(1) {
    background: linear-gradient(135deg, #ff4b6e, #ff7676);
  }
  button:nth-child(2) {
    background: linear-gradient(135deg, #4568dc, #b06ab3);
  }
  button:nth-child(3) {
    background: linear-gradient(135deg, #009fff, #00c0ff);
  }
  button:nth-child(4) {
    background: linear-gradient(135deg, #7f00ff, #b76eff);
  }
  button:nth-child(5) {
    background: linear-gradient(135deg, #00b09b, #00d4b4);
  }
  button:nth-child(6) {
    background: linear-gradient(135deg, #ff6b6b, #ffb88c);
  }
  button:nth-child(7) {
    background: linear-gradient(135deg, #36d1dc, #5b86e5);
  }
  button:nth-child(8) {
    background: linear-gradient(135deg, #ff8008, #ffc837);
  }
  button:nth-child(9) {
    background: linear-gradient(135deg, #ff5f6d, #ffc371);
  }
  button:nth-child(10) {
    background: linear-gradient(135deg, #834d9b, #d04ed6);
  }
  button:nth-child(11) {
    background: linear-gradient(135deg, #56ccf2, #2f80ed);
    min-width: 200px;
    height: 100px;
    font-size: 38px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 15px;

    button {
      height: 50px;
      font-size: 14px;
      border-radius: 12px;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;

    button {
      width: 100%;
    }
  }
`;

const RoomDisplay = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: #7928ca;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
`;

const ExitButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  background: linear-gradient(45deg, #ff0080, #7928ca);
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(255, 0, 128, 0.3);
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
    top: 10px;
    right: 10px;
  }
`;

const LocalFeedOverlay = styled.div`
  position: absolute;
  bottom: 100px;
  right: 20px;
  width: 350px;
  height: 500px;
  border: 2px solid #fff;
  border-radius: 8px;
  z-index: 2000;
  background: #000;
  transform: scale(0.2);
`;

const InputContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;

  label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
  }

  input {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 14px;
    width: 80px;
    z-index: 2;

    &:focus {
      outline: none;
      border-color: #7928ca;
    }
  }
`;
